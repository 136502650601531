<template>
  <div
    id="consent_blackbar"
    class="cookie-notice"
    role="complementary"
  />
</template>

<style lang="scss">
/* stylelint-disable-next-line selector-id-pattern */
#consent_blackbar,
#truste-consent-track {
  z-index: 999;
}

// MOBILE
#truste-consent-content {
  box-shadow: 0 0.25rem 2rem rgb(0 0 0 / 30%);
  border-radius: 1rem !important;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem !important;
}

#truste-consent-track {
  width: 100%;
  opacity: 1 !important;
  border-radius: 0;
}

#truste-consent-buttons {
  padding-top: 1.5rem;
}

/* stylelint-disable-next-line selector-id-pattern */
#consent_blackbar .truste-button2 {
  margin: 0;
  font-size: 1rem;
}

/* stylelint-disable-next-line selector-class-pattern */
.truste-messageColumn {
  line-height: 1.25rem;
}

/* stylelint-disable-next-line selector-class-pattern */
.truste-messageColumn a {
  color: white;
}

// MOBILE BIG SCREEN
@media screen and (width <= 640px) and (width >= 602px) {
  /* stylelint-disable-next-line selector-id-pattern */
  #consent_blackbar .truste-button2 {
    width: max-content;
  }
}

// TABLET
@media screen and (width <= 1023px) and (width >= 641px) {
  /* stylelint-disable-next-line selector-id-pattern */
  #consent_blackbar .truste-button2 {
    padding: 0.5rem 2rem;
    width: max-content;
  }

  .hw-cookie-logo-truste {
    width: 2.5rem;
  }

  #truste-consent-track {
    border-radius: 0 !important;
    bottom: 0 !important;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .truste-messageColumn {
    padding: 2rem !important;
    width: calc(100% - 3.5rem) !important;
    line-height: 1.25rem;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .banner_textconsent {
    display: flex !important;
    align-items: center;
  }

  #truste-consent-content {
    padding: 0 2rem !important;
  }

  #truste-consent-buttons {
    padding-top: 0;
  }
}

// DESKTOP
@include desktop {
  #truste-consent-track {
    width: calc(100% - 4rem);
    max-width: 71.25rem !important;
    border-radius: 1rem !important;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #consent_blackbar .truste-banner {
    padding: 0 2.5rem;
  }

  #truste-consent-content {
    padding: 0 2.5rem !important;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .truste-messageColumn {
    padding: 2.5rem 2rem;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .banner_textconsent {
    display: flex !important;
    align-items: center;
  }

  #truste-consent-buttons {
    padding-top: 0;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #consent_blackbar .truste-button2 {
    width: max-content;
  }
}
</style>
